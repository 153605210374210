import Vue from 'vue';
import Router from 'vue-router';
import { getToken, isloggedIn, isPriorityUser, isEngineer, isSuperUserUniversity } from './../js/authorization';
import { UserRole } from './../js/constants';
import * as http from './../js/service';
import UtilsMixins from './../_mixins/utils-mixins';
import store from './../_store/index';
import { getFakeUrl, fakeAbbr } from '../_helpers/fake-url';


// ================ load component ================
const Login = () => import('./../views/global/login');
const Profile = () => import('./../views/global/profile');
const Home = () => import('./../views/home');
const Register = () => import('./../views/register');
const TeamDetail = () => import('./../views/team-details');
const TeamAll = () => import('./../views/master-team');
const Test = () => import('./../views/test');


// master
const MasterTacher = () => import('./../views/master-teacher');
const MasterMentor = () => import('./../views/master-mentor');
const MasterMember = () => import('./../views/master-member');
const MasterActivity = () => import('./../views/master-activity');
const MasterUniversity = () => import('./../views/master-university');
const MasterFaculty = () => import('./../views/master-faculty');
const MasterMajor = () => import('./../views/master-major');
const History = () => import('./../views/history');



Vue.use(Router)


const router = new Router({
  mode: 'history',
  routes: [

    {
      path: '/',
      name: 'home',
      meta: { title: "Home", requireAuth: true, permission: ["*"] },
      beforeEnter: (to, from, next) => {
        if (isloggedIn()) {
          if (isPriorityUser() || isSuperUserUniversity()) {
            next('/team-all')
          } else {
            next('/team-details')
          }
        } else {
          next('/login')
        }
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { fullscreen: true },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { title: "Profile", requireAuth: true, permission: ["*"] }
    },
    {
      path: '/member-registration',
      name: 'member-registration',
      component: Register,
      meta: {
        fullscreen: true, requireAuth: false, permission: ["*"]
      },
    },
    {
      path: '/team-details/:teamId?',
      name: 'team-details',
      component: TeamDetail,
      props: true,
      meta: {
        fullscreen: false, requireAuth: true, permission: [UserRole.ADMIN, UserRole.MENTOR, UserRole.SUPER_USER_UNIVERSITY, UserRole.MEMBER]
      },
    },
    {
      path: '/team-all',
      name: 'team-all',
      component: TeamAll,
      props: true,
      meta: {
        fullscreen: false, title: "ทีมทั้งหมด", requireAuth: true, permission: [UserRole.ADMIN, UserRole.SUPER_USER_UNIVERSITY, UserRole.MENTOR]
      },
    },
    {
      path: '/master-teacher/:id?',
      name: 'master-teacher',
      component: MasterTacher,
      props: true,
      meta: {
        fullscreen: false, title: "อาจารย์ที่ปรึกษา", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MENTOR, UserRole.SUPER_USER_UNIVERSITY]
      },
    },
    {
      path: '/master-mentor/:id?',
      name: 'master-mentor',
      component: MasterMentor,
      props: true,
      meta: {
        fullscreen: false, title: "นักธุรกิจจิตอาสา", requireAuth: true, permission: [UserRole.ADMIN]
      },
    },
    {
      path: '/master-member/:id?',
      name: 'master-member',
      component: MasterMember,
      props: true,
      meta: {
        fullscreen: false, title: "สมาชิก", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MENTOR]
      },
    },
    {
      path: '/master-activity/:id?',
      name: 'master-activity',
      component: MasterActivity,
      props: true,
      meta: {
        fullscreen: false, title: "กิจกรรมของทีม", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MENTOR]
      },
    },
    {
      path: '/history/:id?',
      name: 'history',
      component: History,
      props: true,
      meta: {
        fullscreen: false, title: "ประวัติการบริจาคโลหิต", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MENTOR]
      },
    },
    {
      path: '/master-university/:id?',
      name: 'master-university',
      component: MasterUniversity,
      props: true,
      meta: {
        fullscreen: false, title: "สถานศึกษา", requireAuth: true, permission: [UserRole.ADMIN]
      },
    },
    {
      path: '/master-faculty/:id?',
      name: 'master-faculty',
      component: MasterFaculty,
      props: true,
      meta: {
        fullscreen: false, title: "คณะ", requireAuth: true, permission: [UserRole.ADMIN]
      },
    },
    {
      path: '/master-major/:id?',
      name: 'master-major',
      component: MasterMajor,
      props: true,
      meta: {
        fullscreen: false, title: "สาขา", requireAuth: true, permission: [UserRole.ADMIN]
      },
    },
    {
      path: '/test',
      name: 'test',
      component: Test,
      props: true,
      meta: {
        fullscreen: false, title: "test", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MENTOR]
      },
    },
  ]
})



router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  store.commit('pageinfo/setTitle', title);
  // redirect to login page if not logged in and trying to access a restricted page
  if (to.name === 'login') {
    next();
    return;
  }

  let expired = isExpireToken()
  if (expired) {
    UtilsMixins.methods.showAlertError("Token has been expired. Please sign in.")
    next('/login');
    return;
  }

  if (to.meta.requireAuth && !isloggedIn()) {
    UtilsMixins.methods.showAlertError("Please sign in.")
    next('/login');
    return;
  }

  if (UtilsMixins.methods.hasPermission(to.meta.permission)) {
    next()
    return;

  } else {
    UtilsMixins.methods.showAlertError("You don't have enough permission to access this page.")
    next("/")
  }
})

function isExpireToken() {
  let obj = getToken();
  let { expiration } = obj;
  if (new Date(expiration) < new Date()) {
    return true
  } else {
    return false
  }
}

export default router;
